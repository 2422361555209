@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500;700&display=swap');

body {
     overflow: auto;
     background: #070B0F;
     font-family: 'Ubuntu', sans-serif;
     #root {
          #app {
               width: 100%;
               height: 100%;
               text-align: center;
               opacity: 0;
               header {
                    position: fixed;
                    top: 0;
                    width: 100%;
                    z-index: 1;
                    div {
                         padding: 16px;
                         width: calc(100% - 32px);
                         p {
                              color: #ff4444;
                              font-size: 20px;
                              animation: fiesta 2s linear infinite;
                              filter: drop-shadow(0 0 16px #070B0F);
                              &:last-of-type {
                                   font-size: 56px; }
                              a {
                                   filter: drop-shadow(0 0 16px #070B0F);
                                   text-decoration: none;
                                   color: #ff4444;
                                   animation: fiesta 2s linear 0.1s infinite;
                                   transition: 0.5s cubic-bezier(.22,.61,.36,1);
                                   &:hover {
                                        transition: 0.5s cubic-bezier(.22,.61,.36,1);
                                        color: #ff4444;
                                        animation: fiestaHover 2s linear infinite; } } } } }
               .contentImg {
                    overflow: hidden;
                    width: auto;
                    height: auto;
                    section {
                         width: 627.5px;
                         height: 1198.5px;
                         // background: #1a2229
                         transform-origin: center center;
                         margin: 150px auto;
                         position: relative;
                         transition: 0.5s cubic-bezier(.22,.61,.36,1);
                         left: 50%;
                         margin-left: calc(-627.5px / 2);
                         .papel {
                              width: 620px;
                              height: 469px;
                              position: absolute;
                              top: 550.25px;
                              left: 4px; }
                         .pan {
                              width: 627.5px;
                              height: 480.5px;
                              position: absolute;
                              top: 287.25px;
                              left: 0px; }
                         .cubierta {
                              width: 566.5px;
                              height: 370.5px;
                              position: absolute;
                              top: 259.25px;
                              left: 37px; }
                         .corazon {
                              width: 89px;
                              height: 121px;
                              position: absolute;
                              top: 748.75px;
                              left: 270.25px;
                              cursor: pointer;
                              animation: temblor 2s ease-out 1s infinite; }
                         .boca01 {
                              width: 357px;
                              height: 178.5px;
                              position: absolute;
                              top: 802px;
                              left: 129.25px;
                              cursor: pointer;
                              -ms-transform: scale(1);
                              transform: scale(1);
                              transition: 0.5s cubic-bezier(.22,.61,.36,1);
                              animation: temblor 2s ease-out 0.5s infinite;
                              &:hover {
                                   -ms-transform: scale(0.99);
                                   transform: scale(0.99);
                                   transition: 0.5s cubic-bezier(.22,.61,.36,1);
                                   animation: temblor02 2s ease-out 0.5s infinite; } }
                         .boca02 {
                              width: 357px;
                              height: 178.5px;
                              position: absolute;
                              top: 802px;
                              left: 128.25px;
                              cursor: pointer;
                              -ms-transform: scale(1);
                              transform: scale(1);
                              transition: 0.5s cubic-bezier(.22,.61,.36,1);
                              &:hover {
                                   -ms-transform: scale(0.99);
                                   transform: scale(0.99);
                                   transition: 0.5s cubic-bezier(.22,.61,.36,1); }
                              &:hover ~ .baba {
                                   -ms-transform: scale(0.98);
                                   transform: scale(0.98);
                                   transition: 0.5s cubic-bezier(.22,.61,.36,1); } }
                         .ojoabierto {
                              left: 269.5px;
                              top: 634.5px;
                              width: 86.5px;
                              height: 109.5px;
                              position: absolute;
                              opacity: 1;
                              cursor: pointer;
                              animation: parpadeo01 2s linear infinite;
                              img {
                                   opacity: 1;
                                   &:hover {
                                        opacity: 0; } } }
                         .ojocerrado {
                              left: 266.75px;
                              top: 634.5px;
                              width: 94px;
                              height: 109.5px;
                              position: absolute;
                              opacity: 1;
                              filter: drop-shadow(0 8px 4px #ff9b00); }
                         .llama {
                              left: 280px;
                              top: 0px;
                              width: 97.5px;
                              height: 169.5px;
                              position: absolute;
                              -ms-transform: translateY(0);
                              transform: translateY(0);
                              transition: 0.5s cubic-bezier(.22,.61,.36,1);
                              cursor: pointer;
                              &:hover {
                                   -ms-transform: translateY(14px);
                                   transform: translateY(14px);
                                   transition: 0.5s cubic-bezier(.22,.61,.36,1); }
                              &:hover ~ .vela {
                                   -ms-transform: translateY(14px);
                                   transform: translateY(14px);
                                   transition: 0.5s cubic-bezier(.22,.61,.36,1); }
                              img {
                                   -ms-transform: rotate(-5deg);
                                   transform: rotate(-5deg);
                                   transform-origin: center bottom;
                                   animation: rotate 2s linear infinite; }
                              &::before, &::after {
                                   content: ' ';
                                   width: 200px;
                                   height: 200px;
                                   background: rgba(0, 255, 166, 0.24);
                                   display: block;
                                   position: absolute;
                                   left: 50%;
                                   z-index: -1;
                                   margin-left: -100px;
                                   top: 50%;
                                   margin-top: -100px;
                                   border-radius: 50%;
                                   opacity: 1;
                                   -ms-transform: scale(0);
                                   transform: scale(0); }
                              &::before {
                                   animation: fuego 2s ease-out infinite; }
                              &::after {
                                   width: 150px;
                                   height: 150px;
                                   margin-left: -75px;
                                   margin-top: -75px;
                                   background: rgba(0, 255, 166, 0.24);
                                   animation: fuego 2s ease-out 0.5s infinite; } }
                         .vela {
                              left: 255px;
                              top: 118.5px;
                              width: 139.5px;
                              height: 279.5px;
                              position: absolute;
                              -ms-transform: translateY(0);
                              transform: translateY(0);
                              transition: 0.5s cubic-bezier(.22,.61,.36,1);
                              cursor: pointer; }
                         .capa01 {
                              left: 211px;
                              top: 287px;
                              width: 249.5px;
                              height: 168.5px;
                              position: absolute; }
                         .ojo01 {
                              left: 283px;
                              top: 424.75px;
                              width: 169.5px;
                              height: 185px;
                              position: absolute; }
                         .cereza {
                              left: 283px;
                              top: 420.25px;
                              width: 209.5px;
                              height: 190px;
                              position: absolute;
                              filter: drop-shadow(0 8px 16px #00d0ff) hue-rotate(0deg);
                              transition: 0.5s cubic-bezier(.22,.61,.36,1);
                              cursor: pointer;
                              -ms-transform: rotate(0deg);
                              transform: rotate(0deg);
                              animation: hueRotate 1s ease-out infinite, temblor 2s ease-out infinite;
                              &:hover {
                                   opacity: 0;
                                   transition: 0.5s cubic-bezier(.22,.61,.36,1); } }
                         .baba {
                              left: 199.1177px;
                              top: 920.5px;
                              width: 229px;
                              height: 278px;
                              position: absolute;
                              -ms-transform: scale(1);
                              transform: scale(1);
                              transition: 0.5s cubic-bezier(.22,.61,.36,1); }
                         .img {
                              img {
                                   width: 100%;
                                   height: auto; } }
                         .teamo {
                              width: 300px;
                              height: 100px;
                              border-radius: 32px;
                              position: absolute;
                              left: 50%;
                              top: 650px;
                              margin-left: -150px;
                              backdrop-filter: blur(8px);
                              -webkit-backdrop-filter: blur(8px);
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              p {
                                   font-size: 32px;
                                   color: #00ffe5;
                                   filter: drop-shadow(0 0 8px #070B0F); } } } }
               footer {
                    width: 100%;
                    position: fixed;
                    bottom: 0;
                    p {
                         padding: 16px;
                         text-align: center;
                         a {
                              text-decoration: none;
                              color: #ff4444;
                              animation: fiesta 2s linear infinite;
                              transition: 0.5s cubic-bezier(.22,.61,.36,1);
                              filter: drop-shadow(0 0 8px #070B0F);
                              &:hover {
                                   color: #3a3f43;
                                   transition: 0.5s cubic-bezier(.22,.61,.36,1);
                                   animation: fiestaHover 2s linear infinite; } } } } }
          .cargando {
               width: 160px;
               background: #070B0F;
               position: absolute;
               z-index: 5;
               left: 50%;
               top: 50%;
               margin-left: -96px;
               margin-top: calc( -139.45px / 2);
               border-radius: 16px;
               padding: 16px;
               .loaderOjo {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 8px;
                    animation: rotateLoader 1s linear infinite;
                    transform-origin: 83px 23px; }
               p {
                    display: flex;
                    justify-content: center;
                    color: #535f67;
                    animation: fiesta 2s linear infinite; } } } }

@keyframes fuego {
     0% {
          -ms-transform: scale(0);
          transform: scale(0);
          opacity: 1; }
     95% {
          opacity: 0; }
     100% {
          -ms-transform: scale(2);
          transform: scale(2);
          opacity: 0; } }

@keyframes rotate {
     0%, 100% {
          -ms-transform: rotate(-5deg);
          transform: rotate(-5deg); }
     50% {
          -ms-transform: rotate(0);
          transform: rotate(0); } }

@keyframes parpadeo01 {
     0%, 89% {
          opacity: 1; }
     90%, 100% {
          opacity: 0; } }

@keyframes hueRotate {
     0%, 100% {
          filter: drop-shadow(0 8px 16px #00d0ff) hue-rotate(0deg); }
     50% {
          filter: drop-shadow(0 8px 16px #00d0ff) hue-rotate(35deg); } }

@keyframes temblor {
     0%, 6%, 12%, 100% {
          -ms-transform: rotate(0deg);
          transform: rotate(0deg); }
     3%, 9% {
          -ms-transform: rotate(5deg);
          transform: rotate(5deg); } }

@keyframes temblor02 {
     0%, 6%, 12%, 100% {
          -ms-transform: rotate(0deg) scale(0.99);
          transform: rotate(0deg) scale(0.99); }
     3%, 9% {
          -ms-transform: rotate(5deg) scale(0.99);
          transform: rotate(5deg) scale(0.99); } }

@keyframes rotateLoader {
     0% {
          -ms-transform: rotate(0deg);
          transform: rotate(0deg); }
     100% {
          -ms-transform: rotate(360deg);
          transform: rotate(360deg); } }

@keyframes fiesta {
     0%, 100% {
          color: #ff4444; }
     10% {
          color: #ff5050; }
     20% {
          color: #ff7f3a; }
     30% {
          color: #f6ff49; }
     40% {
          color: #62ff4a; }
     50% {
          color: #4bfcff; }
     60% {
          color: #42adff; }
     70% {
          color: #3877ff; }
     80% {
          color: #7834ff; }
     90% {
          color: #ff3af8; } }

@keyframes fiestaHover {
     0%, 100% {
          color: #3a3f43; } }


